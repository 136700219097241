<template>
  <div class="text-center">
    <a
      href="https://myplaceup.com/"
      target="_blank"
    >
      <img
        :src="logo"
        class="header-logo"
        alt="logo"
      />
    </a>

    <div
      v-if="['payment-success', 'product-approved'].includes(page)"
      class="mt-2 text-center"
    >
      <feather-icon
        icon="CheckCircleIcon"
        size="80"
        class="success-color"
      />
    </div>

    <div class="text-left mt-4">
      <p class="font-weight-bold">
        Hey, <span class="app-color">Dimitris!</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/logo/logo.png')
    },

    page() {
      return this.$route.name
    },
  },
}
</script>
