<template>
  <div class="email-template">
    <EmailHeader />
    <slot />
    <EmailFooter />
  </div>
</template>

<script>
import EmailHeader from './header.vue'
import EmailFooter from './footer.vue'

export default {
  components: {
    EmailHeader,
    EmailFooter,
  },
}
</script>

<style lang="scss" src="./email.scss"></style>
