<template>
  <div class="mt-4">
    <hr/>
    <p>If you have any questions about this email, simply reply to this email or reach out to our <a href="mailto:support@myplaceup.com">support team</a> for help.</p>

    <p>
      Cheers, <br/>
      The MyPlaceUp Team
    </p>
  </div>
</template>

<script>
export default {
}
</script>
